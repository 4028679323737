import { useElementShown } from '@hh.ru/analytics-js';
import careerMainArticlesElementShown from '@hh.ru/analytics-js-events/build/career_platform/main/career_main_articles_element_shown';
import careerMainButtonClick from '@hh.ru/analytics-js-events/build/career_platform/main/career_main_button_click';
import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { Button, GridColumn, GridRow, Title, useBreakpoint, VSpacingContainer } from '@hh.ru/magritte-ui';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import { ARTICLES_CATALOG_ROUTE } from 'src/app/routesUtils';
import ArticleCard from 'src/components/Article/ArticleCard';
import getDataQa from 'src/utils/getDataQa';

const TrlKeys = {
    title: 'career-platform.landing.articles-section.title',
    button: 'career-platform.landing.articles-section.button',
};

const DATA_QA_BLOCK = 'articles-section';

const ArticlesSection: TranslatedComponent = ({ trls }) => {
    const { articles } = useSelectorNonNullable((state) => state.landing);

    const { isMobile } = useBreakpoint();
    const sectionRef = useElementShown(careerMainArticlesElementShown);

    const handleClick = () => careerMainButtonClick({ buttonName: 'all_articles' });

    if (!articles.length) {
        return null;
    }

    return (
        <section data-qa={getDataQa(DATA_QA_BLOCK)} ref={sectionRef}>
            <VSpacingContainer default={24}>
                <Title
                    size={isMobile ? 'medium' : 'large'}
                    alignment="center"
                    Element="h2"
                    dataQaTitle={getDataQa(DATA_QA_BLOCK, 'title')}
                >
                    {trls[TrlKeys.title]}
                </Title>
                <GridRow rowGap={isMobile ? 16 : 24} data-qa={getDataQa(DATA_QA_BLOCK, 'list')}>
                    {articles.map((item) => (
                        <GridColumn key={item.id} xs={4} s={8} m={4}>
                            <ArticleCard article={item} showBorder />
                        </GridColumn>
                    ))}
                </GridRow>
                <Button
                    mode="secondary"
                    size="large"
                    stretched
                    Element={SPALink}
                    to={ARTICLES_CATALOG_ROUTE}
                    onClick={handleClick}
                    data-qa={getDataQa(DATA_QA_BLOCK, 'show-all')}
                    isSeoLink
                >
                    {trls[TrlKeys.button]}
                </Button>
            </VSpacingContainer>
        </section>
    );
};

export default translation(ArticlesSection);
