import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { useElementShown } from '@hh.ru/analytics-js';
import authBannerElementShown from '@hh.ru/analytics-js-events/build/career_platform/auth_triggers/auth_banner_element_shown';
import authorizeBannerButtonClick from '@hh.ru/analytics-js-events/build/career_platform/authorization/authorize_banner_button_click';
import { Card, Title, useBreakpoint } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import styles from './styles.less';

interface BannerLoginTemplateProps {
    reverse?: boolean;
    icon?: ReactNode;
    title: ReactNode;
    description: ReactNode;
    textAlignCenterMobile?: boolean;
    dataQaContainer?: string;
    dataQaTitle?: string;
    dataQaDescription?: string;
    link: {
        to: string;
        content: ReactNode;
        dataQa?: string;
    };
}

const BannerLoginTemplate: FC<BannerLoginTemplateProps> = ({
    title,
    description,
    reverse,
    icon,
    link,
    textAlignCenterMobile,
    dataQaContainer,
    dataQaTitle,
    dataQaDescription,
}) => {
    const ref = useElementShown(authBannerElementShown);
    const { isMobile } = useBreakpoint();

    return (
        <Card ref={ref} style="contrast" borderRadius={40} stretched data-qa={dataQaContainer}>
            <div
                className={classNames(styles.loginBanner, {
                    [styles.loginBannerReverse]: reverse,
                })}
            >
                <div className={styles.description}>
                    {icon && <span>{icon}</span>}
                    <Title
                        size="small"
                        style="contrast"
                        descriptionStyle="tertiary"
                        description={description}
                        alignment={textAlignCenterMobile && isMobile ? 'center' : 'left'}
                        Element="h3"
                        dataQaTitle={dataQaTitle}
                        dataQaDescription={dataQaDescription}
                    >
                        {title}
                    </Title>
                </div>
                <SPALink
                    className={styles.enter}
                    to={link.to}
                    data-qa={link.dataQa}
                    onClick={() => authorizeBannerButtonClick()}
                >
                    {link.content}
                </SPALink>
            </div>
        </Card>
    );
};

export default BannerLoginTemplate;
